<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <div>
          <b-card-group deck>
            <b-card header-tag="header" footer-tag="footer">
              <div>
                <br />
                <button
                  v-if="item.is_match !== 3"
                  class="btn btn-success mt-2"
                  form=""
                  @click="$bvModal.show(modalId)"
                >
                  Cập nhật trạng thái đối soát
                </button>
                <br />
                <a
                  class="btn btn-success mt-2"
                  :href="`/#/auto-reconcile/received/list/${item.file_id_received}?${getCurrentQuery}`"
                  >Quay lại</a
                >
              </div>
              <div class="mt-4">
                <div class="row">
                  <div class="col-sm-6">
                    <h3
                      v-if="item.partner_completed_at"
                      class="underline mh-100"
                    >
                      Thông tin giao dịch bên 9Pay
                    </h3>
                    <h3 v-else class="underline mh-100">
                      Thông tin giao dịch trong file đối soát
                    </h3>
                    <table class="table table-bordered table-hover">
                      <tbody>
                        <tr class="gradeU">
                          <td>Đối tác</td>
                          <td>{{ item.fund }}</td>
                        </tr>
                        <tr class="gradeU">
                          <td>MID</td>
                          <td>{{ item.mid_code }}</td>
                        </tr>
                        <tr class="gradeU">
                          <td>Loại giao dịch</td>
                          <td>
                            {{
                              item.transaction_type === "PURCHASE"
                                ? item.fund !== "VPB"
                                  ? "Thanh toán"
                                  : "Nạp tiền"
                                : item.fund !== "VPB"
                                ? "Hoàn tiền"
                                : "Rút tiền"
                            }}
                          </td>
                        </tr>
                        <tr class="gradeU">
                          <td>Mã giao dịch 9Pay</td>
                          <td>{{ item.order_code }}</td>
                        </tr>
                        <tr class="gradeU" v-if="item.wallet_transaction">
                          <td>Mã ví 9Pay</td>
                          <td>{{ item.wallet_transaction.wallet_code }}</td>
                        </tr>
                        <tr class="gradeU">
                          <td>Mã tham chiếu</td>
                          <td>{{ item.partner_code }}</td>
                        </tr>
                        <tr class="gradeU">
                          <td>Giá trị giao dịch</td>
                          <td>{{ item.amount }}</td>
                        </tr>
                        <tr v-if="item.fund === 'ONEPAY'" class="gradeU">
                          <td>Phí giao dịch</td>
                          <td>{{ item.total_fee }}</td>
                        </tr>
                        <tr v-if="item.partner_completed_at" class="gradeU">
                          <td>Thời gian ghi nhận tại đối tác</td>
                          <td>{{ item.partner_completed_at }}</td>
                        </tr>
                        <tr v-if="item.partner_completed_at" class="gradeU">
                          <td>Trạng thái ghi nhận tại đối tác</td>
                          <td>Thành công</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div class="col-sm-6">
                    <h3 class="underline">Thông tin thông soát</h3>
                    <table class="table table-bordered table-hover">
                      <tbody>
                        <tr class="gradeU">
                          <td>Trạng thái đối soát</td>
                          <td>
                            {{ item.is_match === 3 ? "Khớp" : "Không khớp" }}
                          </td>
                        </tr>
                        <tr class="gradeU">
                          <td>Kết quả so khớp</td>
                          <td>
                            {{ getMatchStatus }}
                          </td>
                        </tr>
                        <tr class="gradeU">
                          <td>Gửi kết quả sai lệch cho đối tác</td>
                          <td>
                            {{ item.transaction_detail.request_code || "" }}
                          </td>
                        </tr>
                        <tr class="gradeU">
                          <td>Yêu cầu xử lý sai lệch</td>
                          <td>
                            {{ item.transaction_detail.request_code || "" }}
                          </td>
                        </tr>
                        <tr class="gradeU">
                          <td>Thời điểm gửi</td>
                          <td>{{ item.request_date }}</td>
                        </tr>
                        <tr class="gradeU">
                          <td>Số tiền cần hoàn</td>
                          <td>{{ item.transaction_detail.request_amount }}</td>
                        </tr>
                        <tr class="gradeU">
                          <td>Đối tác phản hồi</td>
                          <td>{{ item.transaction_detail.response_code }}</td>
                        </tr>
                        <tr class="gradeU">
                          <td>Thời điểm phản hồi</td>
                          <td>{{ item.response_date }}</td>
                        </tr>
                        <tr class="gradeU">
                          <td>Người xử lý</td>
                          <td>{{ item.transaction_detail.updated_by }}</td>
                        </tr>
                        <tr class="gradeU">
                          <td>Thời điểm xử lý</td>
                          <td>{{ item.transaction_detail.updated_at }}</td>
                        </tr>
                        <tr class="gradeU">
                          <td>Kết quả xử lý</td>
                          <td>
                            {{
                              item.transaction_detail.status === "PROCESSED"
                                ? "Đã xử lý"
                                : "Chờ xử lý"
                            }}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </b-card>
          </b-card-group>
        </div>
      </div>
    </div>
    <b-modal :id="modalId" title="Cập nhật trạng thái đối soát">
      <br />
      <div class="d-block">
        <div class="mb-8">
          <div class="mb-2">
            <label>Hệ thống:</label>
            <select v-model="form_data.is_match" class="form-control">
              <option value="3">Đã khớp</option>
            </select>
          </div>
          <div class="mb-2">
            <label for="">Ghi chú (*)</label>
            <textarea
              class="form-control"
              rows="3"
              id=""
              required=""
              name="match_note"
              cols="50"
              v-model="form_data.match_note"
            >
test1</textarea
            >
          </div>
        </div>
      </div>
      <template #modal-footer="{ ok, cancel }">
        <b-button size="sm" variant="danger" @click="$bvModal.hide(modalId)">
          Hủy
        </b-button>
        <b-button size="sm" variant="success" @click="updateStatus()">
          Cập nhật</b-button
        >
      </template>
    </b-modal>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import Common from "@/core/mixins/common";
import RepositoryFactory from "@/core/repositories/repositoryFactory";

const CmsRepository = RepositoryFactory.get("cms");

export default {
  name: "Reconcile",
  mixins: [Common],
  data() {
    return {
      form_data: {
        is_match: 3,
        match_note: "",
      },
      modalId: "update-status",
      item: {},
      match_status: [
        { value: 0, name: "Không khớp" },
        { value: 1, name: "Đối tác thành công, 9Pay không" },
        { value: 2, name: "9Pay thành công, đối tác không" },
        { value: 3, name: "Khớp đúng" },
        { value: 4, name: "Không khớp phí" },
      ],
    };
  },
  computed: {
    ...mapGetters(["layoutConfig"]),

    config() {
      return this.layoutConfig();
    },
    getCurrentQuery() {
      return new URLSearchParams(this.$route.query).toString();
    },
    getMatchStatus() {
      return (
        this.match_status.find((s) => s.value === this.item.is_match)?.name ||
        "Chưa có"
      );
    },
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Chi tiết đối soát" }]);

    this.getList();
  },
  methods: {
    async updateStatus() {
      if (!this.form_data.is_match || !this.form_data.match_note) {
        this.notifyAlert("warn", "Vui lòng chọn lý do.");
        return;
      }
      try {
        this.$bus.$emit("show-loading", true);
        let response = await CmsRepository.receivedUpdate(
          this.$route.params.id,
          this.form_data
        );
        this.$bus.$emit("show-loading", false);
        if (response.data.data.status) {
          this.notifyAlert(
            "success",
            "Cập nhật trạng thái đối soát thành công."
          );
          await this.getList();
        } else {
          this.notifyAlert("warn", "Có lỗi xảy ra, vui lòng thử lại.");
        }
      } catch (e) {
        this.notifyAlert("warn", "Có lỗi xảy ra, vui lòng thử lại.");
        this.$bus.$emit("show-loading", false);
      }
      this.$bvModal.hide(this.modalId);
    },
    async getList() {
      try {
        this.$bus.$emit("show-loading", true);
        let response = await CmsRepository.getTransactionDetail(
          this.$route.params.id
        );
        this.$bus.$emit("show-loading", false);
        this.item = response.data.data;
      } catch (e) {
        this.$bus.$emit("show-loading", false);
      }
    },
  },
};
</script>
